// Auroræ Grid :: Objects

.#{$ae}-container {
  @include ae-container();
}

.#{$ae}-container-fluid {
  @include ae-container-fluid();
}

.#{$ae}-grid {
  @include ae-grid();
}

@if $ae-alt-grid {
  .#{$ae}-grid--alt {
    @include ae-grid($ae-gutter-alt);
    
    // Only apply '--alt' modifier for over 'sm' breakpoint
    @include ae-breakpoints('sm') {
      @for $i from 1 to ($ae-columns - 1) {
        &#{$i} {
          @include ae-grid( $ae-gutter-alt-magic-number / $i);
        }
      }
    }
  }

  .#{$ae}-grid__item--alt {
    @include ae-grid-item($ae-gutter-alt);
    
    // Only apply '--alt' modifier for over 'sm' breakpoint
    @include ae-breakpoints('sm') {
      @for $i from 1 to ($ae-columns - 1) {
        &#{$i} {
          @include ae-grid-item( $ae-gutter-alt-magic-number / $i);
        }
      }
    }
  }
}


@include ae-grid-items();

@if $ae-masonry {
  @include ae-masonry();
}



