@import "libs/normalize";
// @import "libs/aurora-grid";
@import "libs/aurora-grid";
@import "partials/fonts";

input.error {
  border: 2px solid #ee0000;
}
label.error {
  color: #ee0000;
}
