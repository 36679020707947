// Auroræ Grid :: Helpers

.#{$ae}-grid--collapse {
  margin-left: 0;
  margin-right: 0;

  > .#{$ae}-grid__item {
    padding-left: 0;
    padding-right: 0;
  }
}

.#{$ae}-grid--deepcollapse {
  &, & .#{$ae}-grid {
    margin-left: 0;
    margin-right: 0;
  }

  .#{$ae}-grid__item {
    padding-left: 0;
    padding-right: 0;
  }
}

.#{$ae}-grid__item--collapse {
  padding-left: 0;
  padding-right: 0;
}

.#{$ae}-grid--reverse {
  flex-direction: row-reverse;
}

.#{$ae}-grid--column {
  flex-direction: column;
}

.#{$ae}-grid--column-reverse {
  flex-direction: column-reverse;
}

.#{$ae}-grid--column,
.#{$ae}-grid--column-reverse {
  margin-bottom: ($ae-gutter / -2);
  margin-top: ($ae-gutter / -2);
}

.#{$ae}-grid--column,
.#{$ae}-grid--column-reverse {
  > .#{$ae}-grid__item {
    flex: none;
    padding-bottom: $ae-gutter / 2;
    padding-top: $ae-gutter / 2;
  }
}

.#{$ae}-grid--equal-height,
.#{$ae}-grid--deep-equal-height {
  .#{$ae}-grid__item {
    display: flex;
  }
  .#{$ae}-grid {
    flex: 1;
  }
}

.#{$ae}-grid--deep-equal-height {
  .#{$ae}-grid__item {
    flex: 1;
  }
}
